import { Directive, forwardRef, Attribute } from '@angular/core';
import { FormBuilder, FormGroup,FormControl, Validators,FormArray, FormControlName,AbstractControl,ValidatorFn } from '@angular/forms';

 export function compareValidators(fg: FormGroup){
 const start : any = Number.parseFloat(fg.get('flash_temperature_start').value) || null;
 const end  : any = Number.parseFloat(fg.get('flash_temperature_end').value) || null;
 const inputTemp  : any = Number.parseFloat(fg.get('input_temperature').value) || null;
 const deg_coef_1  : any = Number.parseFloat(fg.get('degassing_coefficient_1').value) || null;
 const deg_coef_2  : any = Number.parseFloat(fg.get('degassing_coefficient_2').value) || null;
 const deg_coef_3  : any = Number.parseFloat(fg.get('degassing_coefficient_3').value) || null;


  if(start !== null && end !== null){
    if( start > end || start == end){
        fg.get('flash_temperature_end').setErrors({ compareValidate: true })
    }
    else{
      if( fg.get('flash_temperature_end').hasError('compareValidate')){
        delete fg.get('flash_temperature_end').errors['compareValidate']
        fg.get('flash_temperature_end').updateValueAndValidity();
      }
    }
  }

  if(start !== null && inputTemp !== null){
    if( start > inputTemp ){
        fg.get('input_temperature').setErrors({ InputTempValidate: true });
    }
    else{
      if( fg.get('input_temperature').hasError('InputTempValidate')){
      delete fg.get('input_temperature').errors['InputTempValidate']
      fg.get('input_temperature').updateValueAndValidity();
      }

    }
  };

    if(deg_coef_1  &&  deg_coef_2 &&  deg_coef_3 && (deg_coef_1 == deg_coef_2 && deg_coef_2 == deg_coef_3 && deg_coef_3 == deg_coef_1)){
        fg.get('degassing_coefficient_1').setErrors({ degCoeffEqualValidate: true });
        fg.get('degassing_coefficient_2').setErrors({ degCoeffEqualValidate: true });
        fg.get('degassing_coefficient_3').setErrors({ degCoeffEqualValidate: true });
      }

     else if(deg_coef_1  &&  deg_coef_2 && (deg_coef_1 == deg_coef_2)){
        fg.get('degassing_coefficient_1').setErrors({ degCoeffEqualValidate: true });
        fg.get('degassing_coefficient_2').setErrors({ degCoeffEqualValidate: true });
      }

      else if(deg_coef_2 &&  deg_coef_3 && (deg_coef_2 == deg_coef_3))
     {
      fg.get('degassing_coefficient_2').setErrors({ degCoeffEqualValidate: true });
      fg.get('degassing_coefficient_3').setErrors({ degCoeffEqualValidate: true });
     }

     else if(deg_coef_3 && deg_coef_1 && (deg_coef_3 == deg_coef_1))
     {
      fg.get('degassing_coefficient_1').setErrors({ degCoeffEqualValidate: true });
      fg.get('degassing_coefficient_3').setErrors({ degCoeffEqualValidate: true });
     }
      
      else{
        if( fg.get('degassing_coefficient_3').hasError('degCoeffEqualValidate')){
        delete fg.get('degassing_coefficient_3').errors['degCoeffEqualValidate']
        fg.get('degassing_coefficient_3').updateValueAndValidity();
        }
       
        if(fg.get('degassing_coefficient_2').hasError('degCoeffEqualValidate')){
          delete fg.get('degassing_coefficient_2').errors['degCoeffEqualValidate']
          fg.get('degassing_coefficient_2').updateValueAndValidity();
        }
        
        if(fg.get('degassing_coefficient_1').hasError('degCoeffEqualValidate')){
          delete fg.get('degassing_coefficient_1').errors['degCoeffEqualValidate']
          fg.get('degassing_coefficient_1').updateValueAndValidity();
        }
      }
 }  



