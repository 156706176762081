import { Component, OnInit,ElementRef, ViewChild  } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../_services/authentication.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ChangeDetectorRef, TemplateRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpRequestService } from '../_services/http-req-service';
import { CustomvalidationService } from '../_services/cutomValidation';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})

export class RegisterComponent implements OnInit {
    public registerForm: FormGroup;
    public loading = false;
    public submitted = false;
    public modalRef: BsModalRef;
    public model: any = {};
    public disabledAgreement: boolean = true;
    public islogin : boolean = true;

    
    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private authenticationService: AuthenticationService,
        private modalService: BsModalService,
        public httpRequestService : HttpRequestService,
        public toasterService: ToastrService,
        private customValidator: CustomvalidationService 
    ) { 
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) { 
            this.router.navigate(['/']);
        }
        this.islogin = true;
    }

    config = {
      allowNumbersOnly: false,
      length: 6,
      isPasswordInput: false,
      disableAutoFocus: false,
      placeholder: '',
      inputStyles: {
        'width': '40px',
        'height': '40px'
      }
    };

    onOtpChange(otp) {
        this.model.model_otp = otp;
      }
    
    ngOnInit() {
        this.registerForm = this.formBuilder.group({
            firstname: ['', [Validators.required,Validators.maxLength(60),Validators.minLength(3)]],
            lastname: ['', [Validators.required,Validators.maxLength(60),Validators.minLength(3)]],
            companyname: ['', [Validators.required,Validators.maxLength(60),Validators.minLength(3)]],
            email : ['', [Validators.required,Validators.pattern("^[_A-Za-z0-9-\+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$")]],
            isteamcontact: [''],
            countryname: ['',[Validators.required]],
            terms: [false, Validators.requiredTrue],
        });
        this.islogin = true;
    }

    // convenience getter for easy access to form fields
    get f() { return this.registerForm.controls; }
    
    changeCheck(event: any){
        this.disabledAgreement = !event.target.checked;
    }

    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }
        else{
            this.router.navigate(['/login']);
        }
    }

    onReset(){
        this.registerForm.reset();
    }

    openModal() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }
        else{
            const email : any = this.registerForm.controls['email'].value;                
                this.httpRequestService.getVerifyEmail(email).subscribe(data=>{
                    if(data.length == 0){
                        const obj :any ={
                            email : this.registerForm.controls['email'].value
                        }
                        this.httpRequestService.postSendEmail(obj).subscribe(data=>{
                            if(Object.keys(data).length>0){
                                this.model.model_otp = "";
                                this.islogin = false;
                            }
                            else{
                            }
                        });
                    }
                    else{
                        this.toasterService.error("Email Address is already registered","",{ positionClass: 'toast-top-center' });
                    }
                });
        }
      }

    openModalATC(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template,Object.assign({}, { class: 'gray modal-lg' }));
    }
    
    onResendOTP(){
        const obj :any = this.registerForm.value;
        this.httpRequestService.postSendEmail(obj).subscribe(data=>{
            if(Object.keys(data).length>0){
                this.toasterService.info("OTP sent to your Email Address","",{ positionClass: 'toast-top-center' });
            }
            else{
                this.toasterService.error("Resend OTP","",{ positionClass: 'toast-top-center' });
            }
        });
    }

    submitOTP(){
	const teamContact = this.registerForm.controls['isteamcontact'].value;
        if(teamContact == true){
            this.registerForm.controls['isteamcontact'].setValue("Yes");
        }
	else if(teamContact == ""){
 	    this.registerForm.controls['isteamcontact'].setValue("No");
	}
        else{
            this.registerForm.controls['isteamcontact'].setValue("No");
        }

        const data : any = {
            action:"REGISTRATION",
            firstname:this.registerForm.controls['firstname'].value,
            lastname:this.registerForm.controls['lastname'].value,
            companyname:this.registerForm.controls['companyname'].value,
            email:this.registerForm.controls['email'].value,
            otp:this.model.model_otp,
            terms:this.registerForm.controls['terms'].value,
            isteamcontact: this.registerForm.controls['isteamcontact'].value,
            countryname: this.registerForm.controls['countryname'].value,
         }
         
        const dataRefreshToken = {
            email:this.registerForm.controls['email'].value,
            tokencode:this.model.model_otp
        };
         
        this.httpRequestService.postOTPDetails(data).subscribe(data =>{
            if(Object.keys(data).length>0){
                if(data.status == "Authenticated"){
                    sessionStorage.setItem('currentUser', JSON.stringify(data));
                    this.authenticationService.currentUserSubject.next(data); 
                    sessionStorage.setItem('currentToken', JSON.stringify(data.token));
                    this.authenticationService.currentTokenubject.next(this.model.model_otp); 
                    this.model.model_otp = "";
                    this.router.navigate(['/home']);
                }
                else{
                    alert("Registeration is not completed")
                }
            }
        });
    }
    
    public CountryList : any = [
        "Afghanistan",
        "Albania",
        "Algeria",
        "American Samoa",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antarctica",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia",
        "Bosnia and Herzegovina",
        "Botswana",
        "Bouvet Island",
        "Brazil",
        "British Antarctic Territory",
        "British Indian Ocean Territory",
        "British Virgin Islands",
        "Brunei",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Canton and Enderbury Islands",
        "Cape Verde",
        "Cayman Islands",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Christmas Island",
        "Cocos [Keeling] Islands",
        "Colombia",
        "Comoros",
        "Congo - Brazzaville",
        "Congo - Kinshasa",
        "Cook Islands",
        "Costa Rica",
        "Croatia",
        "Cuba",
        "Cyprus",
        "Czech Republic",
        "C�te d�Ivoire",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "Dronning Maud Land",
        "East Germany",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Ethiopia",
        "Falkland Islands",
        "Faroe Islands",
        "Fiji",
        "Finland",
        "France",
        "French Guiana",
        "French Polynesia",
        "French Southern Territories",
        "French Southern and Antarctic Territories",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Heard Island and McDonald Islands",
        "Honduras",
        "Hong Kong SAR China",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Isle of Man",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jersey",
        "Johnston Island",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Kuwait",
        "Kyrgyzstan",
        "Laos",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macau SAR China",
        "Macedonia",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Metropolitan France",
        "Mexico",
        "Micronesia",
        "Midway Islands",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar [Burma]",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "Netherlands Antilles",
        "Neutral Zone",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Niue",
        "Norfolk Island",
        "North Korea",
        "North Vietnam",
        "Northern Mariana Islands",
        "Norway",
        "Oman",
        "Pacific Islands Trust Territory",
        "Pakistan",
        "Palau",
        "Palestinian Territories",
        "Panama",
        "Panama Canal Zone",
        "Papua New Guinea",
        "Paraguay",
        "People's Democratic Republic of Yemen",
        "Peru",
        "Philippines",
        "Pitcairn Islands",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Romania",
        "Russia",
        "Rwanda",
        "R�union",
        "Saint Barth�lemy",
        "Saint Helena",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Martin",
        "Saint Pierre and Miquelon",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Serbia and Montenegro",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Georgia and the South Sandwich Islands",
        "South Korea",
        "Spain",
        "Sri Lanka",
        "Sudan",
        "Suriname",
        "Svalbard and Jan Mayen",
        "Swaziland",
        "Sweden",
        "Switzerland",
        "Syria",
        "S�o Tom� and Pr�ncipe",
        "Taiwan",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks and Caicos Islands",
        "Tuvalu",
        "U.S. Minor Outlying Islands",
        "U.S. Miscellaneous Pacific Islands",
        "U.S. Virgin Islands",
        "Uganda",
        "Ukraine",
        "Union of Soviet Socialist Republics",
        "United Arab Emirates",
        "United Kingdom",
        "United States",
        "Unknown or Invalid Region",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Vatican City",
        "Venezuela",
        "Vietnam",
        "Wake Island",
        "Wallis and Futuna",
        "Western Sahara",
        "Yemen",
        "Zambia",
        "Zimbabwe",
        "�land Islands"
        ];
}
