import { Component, ChangeDetectionStrategy, OnInit, TemplateRef ,AfterViewInit, ViewChild, ViewContainerRef,ElementRef} from '@angular/core';
import { FormBuilder, FormGroup,FormControl, Validators,FormArray, FormControlName,AbstractControl,ValidatorFn } from '@angular/forms';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import * as $ from 'jquery' ;
import { HttpRequestService } from '../_services/http-req-service';
import { findIndex } from 'rxjs/operators';
import { Router,ActivatedRoute  } from '@angular/router';
import { DataService } from '../_services/data.service';
import { param } from 'jquery';
import { compareValidators } from '../_services/validator';
import { ToastrService } from 'ngx-toastr';
import removeTrailingZeros from 'remove-trailing-zeros';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as WatchActions from '../_services/watch.action';
import {  AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'app-watch-input-form',
  templateUrl: './watch-input-form.component.html',
  styleUrls: ['./watch-input-form.component.css']
})

export class WatchInputFormComponent implements OnInit {
  data: any = {};
  calculate$ : any = {};

  public formoneData : any = {};
  public id_test : any;
  public formPatchData : any = {};
  public is_edit: boolean = false;
  public active : any;
  public CustomerName: any = [
    { name:'Customer A',id:'123_ABC'},
    { name:'Customer B',id:'John' },
    { name:'Customer C',id:'33' },
    { name:'Customer D',id:'Vishal' },
    { name:'Customer E',id:'55' }
  ];

  public RegionName: any = [
    { name:'AP',id:'1'},
    { name:'EMEA',id:'2' },
    { name:'LA',id:'3' },
    { name:'NA',id:'4' }
  ];


  public Tempraturetype : any = [
    {"value": '0',"name":'Measured'}, 
    {"value": '3',"name":'Quartz Calculated'},
  ];

  public isCustomer : boolean = false;
  public isMeaserdTemp : boolean =false;
  public tabList : any = [];
  public modalRef: BsModalRef;
  public modalSaveRef : BsModalRef;
  public modalReset : BsModalRef;
  public message: string;
  public templateId : any;
  public isChecked : boolean = false;
  public customerId : any = "";
  public preparedBy : any;
  public userEmail : any;
  public submitted = false;
  public isSecondPhase : boolean = true;
  public isRequiredError : boolean = false;
  public isBoilingTemp : boolean = false;
  public isNoofDegCoeff : boolean = false;
  public isDegCoef2 : boolean = false;
  public isDegCoef3 : boolean = false;
  public formDataObj : any = {};
  public isUnit4 : boolean = false;
  public steamOnChangeObj : any =[];
  public shareDataKey : any;
  public isDisabled : boolean = false;
  public timecreated : any;
  public isCheck : boolean = false;
  public canViewIPhase: boolean = false;
  public currentUserObj : any = {};

  formDetails = new FormGroup ({ 
      customer_id : new FormControl('customer_id'), 
      location :  new FormControl('location'), 
      name :  new FormControl('name'), 
      systemname :  new FormControl('systemname'), 
      modelname :  new FormControl('modelname'), 
      region :  new FormControl('region'),
      sample_name : new FormControl('sample_name'), 
      sample_location :  new FormControl('sample_location'), 
      temperature :  new FormControl('temperature'), 
      pressure :  new FormControl('pressure'), 
      fluidenthalpy :  new FormControl('fluidenthalpy'),
      two_phases_sample :  new FormControl('two_phases_sample'),
      discharge :  new FormControl('discharge'),
      inputfilename : new FormControl('inputfilename'), 
      username :  new FormControl('username'), 
      printoutheading :  new FormControl('printoutheading'), 
      outputfilename :  new FormControl('outputfilename'), 
      configfilename :  new FormControl('configfilename'),
      outputfilename2 :  new FormControl('outputfilename2'), 
      outputfilename3 :  new FormControl('outputfilename3'), 
      temperature_type :  new FormControl('temperature_type'),
      input_temperature :  new FormControl('input_temperature'),
      boiling_temperature :  new FormControl('boiling_temperature'),
      boiling_degassing_coefficient :  new FormControl('boiling_degassing_coefficient'),
      flash_temperature_increment :  new FormControl('flash_temperature_increment'),
      flash_temperature_start :  new FormControl('flash_temperature_start'),
      flash_temperature_end :  new FormControl('flash_temperature_end'),
      No_of_degassing_coefficient :  new FormControl('No_of_degassing_coefficient'),
      degassing_coefficient_1 :  new FormControl('degassing_coefficient_1'),
      degassing_coefficient_2 :  new FormControl('degassing_coefficient_2'),
      degassing_coefficient_3 :  new FormControl('degassing_coefficient_3'),
      water_phase_pH : new FormControl('water_phase_pH'), 
      pH_temperature :  new FormControl('pH_temperature'), 
      CO2_in_water :  new FormControl('CO2_in_water'), 
      H2S_in_water :  new FormControl('H2S_in_water'), 
      NH3_in_water :  new FormControl('NH3_in_water'), 
      H2_in_water :  new FormControl('H2_in_water'), 
      O2_in_water :  new FormControl('O2_in_water'), 
      CH4_in_water :  new FormControl('CH4_in_water'), 
      N2_in_water :  new FormControl('N2_in_water'), 
      SiO2_in_water :   new FormControl('SiO2_in_water'), 
      B_in_water :  new FormControl('B_in_water'), 
      Al_in_water :  new FormControl('Al_in_water'), 
      Fe_in_water :  new FormControl('Fe_in_water'), 
      Na_in_water :  new FormControl('Na_in_water'), 
      K_in_water :  new FormControl('K_in_water'), 
      Mg_in_water :  new FormControl('Mg_in_water'), 
      Ca_in_water :  new FormControl('Ca_in_water'), 
      F_in_water :  new FormControl('F_in_water'), 
      Cl_in_water :  new FormControl('Cl_in_water'), 
      SO4_in_water :  new FormControl('SO4_in_water'), 
      TDS_in_water :  new FormControl('TDS_in_water'), 
      gas_CO2 :  new FormControl('gas_CO2'), 
      gas_H2S :  new FormControl('gas_H2S'), 
      gas_NH3 :  new FormControl('gas_NH3'), 
      gas_H2 :  new FormControl('gas_H2'), 
      gas_O2 :  new FormControl('gas_O2'), 
      gas_CH4 :  new FormControl('gas_CH4'), 
      gas_N2 :  new FormControl('gas_N2'), 
      L_gas :  new FormControl('L_gas'), 
      gas_coll :  new FormControl('gas_coll'), 
      steam_CO2 :  new FormControl('steam_CO2'), 
      steam_H2S :  new FormControl('steam_H2S'), 
      steam_NH3 :  new FormControl('steam_NH3'), 
	    total_gas :  new FormControl('total_gas'),
      unit :  new FormControl('unit'), 
      condensate_pH :  new FormControl('condensate_pH'), 
      Cd_pH_temp :  new FormControl('Cd_pH_temp'), 
      condensate_CO2 :  new FormControl('condensate_CO2'), 
      condensate_H2S :  new FormControl('condensate_H2S'), 
      condensate_NH3 :  new FormControl('condensate_NH3'), 
      condensate_Na :  new FormControl('condensate_Na'), 
    });


  constructor(private store: Store<any>, private formBuilder: FormBuilder,private elementRef:ElementRef,private fb: FormBuilder,private modalService: BsModalService,private router: Router, private httpRequestService : HttpRequestService, private dataService : DataService,private route: ActivatedRoute,private toasterService: ToastrService,
    private authenticationService : AuthenticationService)
   {
    this.CreateForm();
    this.dataService.ShareInputDataSource.subscribe(res =>   {
	  if(Object.keys(res).length>0){
            this.shareDataKey = res.key;
	        if(this.shareDataKey == 'current_simulation'){
                this.timecreated = res.timecreated;
              }
            this.isDisabled = true;
            
             const form_data : any =res.data;
            if(Object.keys(form_data).length>0){
              this.patchInputFormData(form_data);
              const data : any = res.data;
              if(data.degassing_coefficient_2 != null){
                this.isDegCoef2 = true;
              }
              if(data.degassing_coefficient_3 != null){
                this.isDegCoef2 = true; 
                this.isDegCoef3 = true;
              }
              if(data.two_phases_sample == "Yes"){
                this.isSecondPhase = true;
		            if(data.unit == '4'){
                    this.isUnit4 = true;
                  }
                  else{
                    this.isUnit4 = false;

                  }
              }
              else{
                this.isSecondPhase = false;
                this.formDetails.get('gas_CO2').setValidators(null);
                this.formDetails.get('gas_H2S').setValidators(null);
                if(data.boiling_temperature){
                  this.isBoilingTemp = true;
                  this.isCheck = true;
                }
                else{
                  this.isBoilingTemp = false;
                  this.isCheck = false;
                }
              }
              if(data.input_temperature){
                this.isMeaserdTemp = true;
              }
            }
	       }
        });
    }

  ngOnInit(): void {
    this.getUser();
    const  allWells = $('.setup-content');

  }

  public getUser(){
    const currentUser = this.authenticationService.currentUserValue;
    this.currentUserObj = currentUser;
        if (currentUser.customer_id) {
          this.formDetails.get('customer_id').setValue(currentUser.customer_id);
          this.formDetails.get('name').setValue(currentUser.email);
        }
  }

  ngOnchange(): void{
  } 

  public patchInputFormData(data : any){
      if(Object.keys(data).length != 0 ){
        let sampleData : any = data;
        sampleData = this.reverseCalculation(sampleData);
        if(sampleData){
          this.formDetails.patchValue(sampleData);
          }
      }
  }

  public CreateForm(){
    this.formDetails = this.formBuilder.group({
      customer_id: [{ value: '', disable: this.isDisabled },Validators.required],
      location: ['' , [Validators.required, Validators.maxLength(50)]],
      name: ['', Validators.required],
      systemname: [null , Validators.maxLength(50)],
      modelname: [null , [Validators.required, Validators.maxLength(50)]],
      region : ['AP', Validators.required],
      sample_name: ['' , [Validators.required, Validators.maxLength(50)]],
      sample_location : ['' , [Validators.required, Validators.maxLength(50)]],
      temperature:['', Validators.max(5000)],
      pressure: ['' , [Validators.required, Validators.max(1000),Validators.min(0)]],
      fluidenthalpy: ['',Validators.max(4999)],
      discharge: ['0.00000000'],
      two_phases_sample: ['Yes' , Validators.required],
      inputfilename: [null],
      username: [''],
      printoutheading: [null], 
      outputfilename: [null],
      configfilename: [null],
      outputfilename2: [null],
      outputfilename3: [null],
      temperature_type: ['' , Validators.required],
      input_temperature: [null],
      boiling_temperature: [null,[Validators.max(300), Validators.min(100)]],
      boiling_degassing_coefficient: [null , [Validators.max(1), Validators.min(0.01)]],
      flash_temperature_increment: ['10' , Validators.required],
      flash_temperature_start: ['' , [Validators.required, Validators.max(320),Validators.min(100)]],
      flash_temperature_end: ['' , [Validators.required,Validators.max(320),]],
      No_of_degassing_coefficient: ['1'],
      degassing_coefficient_1: [null , [Validators.required, Validators.max(1), Validators.min(0.01)]],
      degassing_coefficient_2: [null, [Validators.max(1), Validators.min(0.01)]],
      degassing_coefficient_3: [null, [Validators.max(1), Validators.min(0.01)]],
      water_phase_pH: ['',[Validators.required,Validators.min(0),Validators.max(14)]], 
      pH_temperature:['',[Validators.required,Validators.max(9999)]], 
      CO2_in_water: ['' , [Validators.required,Validators.max(9999)]], 
      H2S_in_water: ['' , [Validators.required,Validators.max(9999)]], 
      NH3_in_water:['',Validators.max(9999)], 
      H2_in_water: ['',Validators.max(9999)], 
      O2_in_water:['',Validators.max(9999)], 
      CH4_in_water: ['',Validators.max(9999)], 
      N2_in_water: ['',Validators.max(9999)], 
      SiO2_in_water: ['' , [Validators.required,Validators.max(9999)]], 
      B_in_water: ['',Validators.max(9999)], 
      Al_in_water: ['' , [Validators.required,Validators.max(9999)]], 
      Fe_in_water: ['' , [Validators.required,Validators.max(9999)]], 
      Na_in_water: ['',Validators.max(9999)], 
      K_in_water:['',Validators.max(9999)], 
      Mg_in_water: ['',Validators.max(9999)],  
      Ca_in_water: ['' , [Validators.required,Validators.max(9999)]], 
      F_in_water: ['',Validators.max(9999)], 
      Cl_in_water: ['',Validators.max(9999)], 
      SO4_in_water: ['',Validators.max(9999)], 
      TDS_in_water: ['',Validators.max(9999)], 
      gas_CO2: ['',[Validators.required,Validators.max(1000000),Validators.min(0.00000001)]], 
      gas_H2S: ['',[Validators.required,Validators.max(1000000),Validators.min(0.00000001)]], 
      gas_NH3: ['',Validators.max(1000000)],  
      gas_H2: ['',Validators.max(1000000)], 
      gas_O2: ['',Validators.max(1000000)], 
      gas_CH4: ['',Validators.max(1000000)], 
      gas_N2: ['',Validators.max(1000000)], 
      L_gas: ['',Validators.max(1000000)], 
      gas_coll: ['25.00000000',Validators.max(1000000)], 
      steam_CO2: ['',Validators.max(1000000)], 
      steam_H2S: ['',Validators.max(1000000)], 
      steam_NH3: ['',Validators.max(1000000)], 
      total_gas : [null,Validators.max(1000000)], 
      unit : ['1'],
      condensate_pH: ['0.00000000'], 
      Cd_pH_temp:['0.00000000'], 
      condensate_CO2: ['0.00000000'],  
      condensate_H2S:['0.00000000'], 
      condensate_NH3: ['0.00000000'], 
      condensate_Na: ['0.00000000'], 
    },
    { validator: compareValidators }
    // {validator: this.compareValidators('flash_temperature_start', 'flash_temperature_end')}  // key is to validate on the form group
    )};

  get getformDetails() { return this.formDetails.controls; }

   public changeTemperature(e : any){
    if(e.target.value == '0')
    {
      this.isMeaserdTemp = true;
      this.formDetails.get('input_temperature').setValidators([Validators.required])
    }
    else{
      this.isMeaserdTemp = false;
      this.formDetails.get('input_temperature').clearValidators();
     this.resetTabValue('ReserviorTemp');
    }
   }

   public changeRegeion(e : any){
   }
   
  onClickPhaseSample(val :any){
    if(val == "Yes"){
     this.isSecondPhase = true; 
    //  this.tabs[1].title = "II Phase";
     this.formDetails.get('gas_CO2').setValidators([Validators.required,Validators.max(9999),Validators.min(0.00000001) ]);
     this.formDetails.get('gas_H2S').setValidators([Validators.required,Validators.max(9999),Validators.min(0.00000001)]);
     this.formDetails.get('boiling_temperature').setValidators(null);
    //  this.getConfig.get('boiling_degassing_coefficient').setValidators(null);
     this.resetTabValue('IIPhase');
    }
    else{
      this.isSecondPhase = false; 
      this.formDetails.get('gas_CO2').setValidators(null);
      this.formDetails.get('gas_H2S').setValidators(null);
      this.resetTabValue('IPhase');
    }
  }

  onClickUnits(val :any){
    this.formDetails.patchValue(
      { unit: val}
    );
    if(val == '4'){
      this.isUnit4 = true;
    }
    else{
      this.isUnit4 = false;
      this.formDetails.get('total_gas').setValue(null)
    }
  }

  onClickBoilingSample(val :any){
    if(val == "Yes"){
     this.isBoilingTemp = true; 
     this.formDetails.get('boiling_temperature').setValidators([Validators.required,Validators.max(300), Validators.min(200)]);

    }
    else{
      this.isBoilingTemp = false; 
      this.formDetails.get('boiling_temperature').setValidators(null);
      this.resetTabValue('BoilingSample')
    }
  }

  onClickMultipleDegassingCoeff(e : any){
    if(e == '2')
    {
      this.isDegCoef2 = true;
      this.isDegCoef3 = false;
      this.formDetails.get('degassing_coefficient_2').setValidators([Validators.required,Validators.max(1), Validators.min(0.01)])
      this.formDetails.get('degassing_coefficient_3').setValidators(null)
       this.resetTabValue('MultiDegassCoeff2');
    }
    else  if(e == '3'){
      this.isDegCoef3 = true;
      this.isDegCoef2 = true;
      this.formDetails.get('degassing_coefficient_2').setValidators([Validators.required,Validators.max(1), Validators.min(0.01)])
      this.formDetails.get('degassing_coefficient_3').setValidators([Validators.required,Validators.max(1), Validators.min(0.01)])
    }
    else{
      this.isDegCoef2 = false;
      this.isDegCoef3 = false;
      this.formDetails.get('degassing_coefficient_2').setValidators(null)
      this.formDetails.get('degassing_coefficient_3').setValidators(null)
      this.resetTabValue('MultiDegassCoeff');
    }
   }

  public resetTabValue(val : any){
    if(val == 'IPhase'){
      this.formDetails.patchValue({
          gas_CO2: '' ,  
          gas_H2S: '',  
          gas_NH3: '',  
          gas_H2: '',  
          gas_O2: '',  
          gas_CH4: '',
          gas_N2: '',
          L_gas:'',
          gas_coll: '25.00000000', 
          steam_CO2: '', 
          steam_H2S: '',
          steam_NH3: '',
          total_gas:'',
          unit: '1'
        });
    }
    else if(val == 'IIPhase'){
      this.formDetails.patchValue({
          boiling_temperature : null,
          boiling_degassing_coefficient : null, 
        });
        this.formDetails.get('unit').setValue('1')
        this.isBoilingTemp = false;
    }
    else if(val == 'BoilingSample'){
      this.formDetails.patchValue({
          boiling_temperature : null,
          boiling_degassing_coefficient : null, 
      })
    }
    else if(val == 'ReserviorTemp'){
      this.formDetails.patchValue({
          input_temperature : null
      })
    }
    else if(val == 'MultiDegassCoeff2'){
        this.formDetails.patchValue({
          degassing_coefficient_3 : null,
      })
    }
      else if(val == 'MultiDegassCoeff'){
        this.formDetails.patchValue({
          degassing_coefficient_2 : null,
          degassing_coefficient_3 : null,
      })
    }
  }

 public Submit(){
  this.submitted = true;
     if (this.formDetails.invalid) {
       this.toasterService.error("validation failed","",  { positionClass: 'toast-top-center' });
         return;
     }

    this.formDetails.patchValue(
      { username: this.preparedBy}
    );

    const sysname : any = this.formDetails.get('systemname').value;
    if(sysname == null || sysname == ""){
      this.formDetails.get('systemname').setValue("misc");
    }

    const tempobj : any = this.formDetails.value;

    this.formDataObj = this.fixedEightDigits(tempobj);
    this.SubmitFormData();
  }

  public SubmitFormData(): void {
    const postObj : any = this.formDataObj;
	
    if(this.shareDataKey == 'current_simulation'){
      const id : any = this.formDetails.get('customer_id').value;
      const time : any = this.timecreated;
      const tokenDetails = this.authenticationService.getTokenDetails();
      this.httpRequestService.postRefreshtoken(tokenDetails).subscribe(data=>{
      const token = data.token;
      this.httpRequestService.postFormData(postObj,token).subscribe(data=>{
        const tempdata : any = data;
        this.store.dispatch(new WatchActions.SimulateData(tempdata));
        this.router.navigate(['/home/current-stimulation']);
      });
    });
    }
    else{
      const tokenDetails = this.authenticationService.getTokenDetails();
      this.httpRequestService.postRefreshtoken(tokenDetails).subscribe(data=>{
      const token = data.token;
        this.httpRequestService.postFormData(postObj,token).subscribe(data=>{
          const tempdata : any = data;
          this.store.dispatch(new WatchActions.SimulateData(tempdata));
          this.router.navigate(['/home/current-stimulation']);
        });   
      }); 
    }
  }

  public reverseCalculation(data : any){
    const formObj : any= data;
    
    for(const item in formObj){
      if((item == 'temperature') || item == 'pressure' || item == 'fluidenthalpy'){
        formObj[item] = this.removetrailingzero(formObj[item]);
        }
        if(item == 'water_phase_pH' || 
        item == 'pH_temperature' || 
        item == 'CO2_in_water' || 
        item == 'H2S_in_water' || 
        item == 'NH3_in_water' || 
        item == 'H2_in_water' || 
        item == 'O2_in_water' || 
        item == 'CH4_in_water' || 
        item == 'N2_in_water' || 
        item == 'SiO2_in_water' || 
        item == 'B_in_water' || 
        item == 'Al_in_water' || 
        item == 'Fe_in_water' || 
        item == 'Na_in_water' || 
        item == 'K_in_water' || 
        item == 'Mg_in_water' || 
        item == 'Ca_in_water' || 
        item == 'F_in_water' || 
        item == 'Cl_in_water' || 
        item == 'SO4_in_water' || 
        item == 'TDS_in_water'){
          formObj[item] = this.removetrailingzero(formObj[item]);
      }
      if(item == 'gas_CO2' || 
          item == 'gas_H2S' || 
          item == 'gas_NH3' ||
          item == 'gas_H2' ||
          item == 'gas_O2' ||
          item == 'gas_CH4' ||
          item == 'gas_N2' ||
          item == 'L_gas' ||
          item == 'steam_CO2' ||
          item == 'steam_H2S' ||
          item == 'steam_H2S' ||
	        item == 'steam_NH3' ||
          item == 'total_gas' )
          {
            formObj[item] = this.removetrailingzero(formObj[item]);
          }
    }
    return formObj;
  }

  public removetrailingzero(no: any){
    let no_val : any;
    var regEx1 = /^[0]+/;
    var regEx2 = /[0]+$/;
    var regEx3 = /[.]$/;
   
    if(no != "" && no !== undefined &&  no !== null)
    {
        if(!Number.isInteger(no)){
          no_val = no.replace(regEx1,'');   // Remove leading 0's
          if (no_val.indexOf('.')>-1){
            no_val = no_val.replace(regEx2,'');  // Remove trailing 0's
          } 
          if(no_val !== undefined){
            no_val = no_val.replace(regEx3,'');  // Remove trailing decimal
          }
       
         if(no_val == ""){
            no_val =  '0';
          }
          if(no_val.charAt(0) == '.')
          {
            no_val = '0'+no_val;
          }
         no = no_val;
        }        
    }
    return no;
  }
  
    public fixedEightDigits(data : any){
      const formObj : any= data;
      const formKeys : any =  Object.keys(formObj);
  
        for(let i =0;i<=formKeys.length-1; i++){
          const item : any  = formKeys[i];
          if((item == 'temperature') || item == 'pressure' || item == 'fluidenthalpy'){
            if(formObj[item] == ''){
                formObj[item] = '0.00000000';
              }
              else{
                const num  = Number.parseFloat(formObj[item]).toFixed(8);
                formObj[item] = num;
              }
          }
          if(item == 'water_phase_pH' || 
                  item == 'pH_temperature' || 
                  item == 'CO2_in_water' || 
                  item == 'H2S_in_water' || 
                  item == 'NH3_in_water' || 
                  item == 'H2_in_water' || 
                  item == 'O2_in_water' || 
                  item == 'CH4_in_water' || 
                  item == 'N2_in_water' || 
                  item == 'SiO2_in_water' || 
                  item == 'B_in_water' || 
                  item == 'Al_in_water' || 
                  item == 'Fe_in_water' || 
                  item == 'Na_in_water' || 
                  item == 'K_in_water' || 
                  item == 'Mg_in_water' || 
                  item == 'Ca_in_water' || 
                  item == 'F_in_water' || 
                  item == 'Cl_in_water' || 
                  item == 'SO4_in_water' || 
                  item == 'TDS_in_water'){
            if(formObj[item] == ''){
              formObj[item] = '0.00000000';
            }
            else{
              const num  = Number.parseFloat(formObj[item]).toFixed(8);
              formObj[item] = num;
            }
          }
          
          if(item == 'gas_CO2' || 
          item == 'gas_H2S' || 
          item == 'gas_NH3' ||
          item == 'gas_H2' ||
          item == 'gas_O2' ||
          item == 'gas_CH4' ||
          item == 'gas_N2' ||
          item == 'L_gas' ||
          item == 'steam_CO2' ||
          item == 'steam_H2S' ||
          item == 'steam_H2S' ||
	  item == 'steam_NH3' ||
          item == 'total_gas' )
          {
            if(formObj.two_phases_sample == "Yes"){
            if(formObj[item] == '' || formObj[item] == null){
              formObj[item] = '0.00000000';
            }
            else{
              const num  = Number.parseFloat(formObj[item]).toFixed(8);
              formObj[item] = num;
            }
          }
          else{
            formObj[item] = '0.00000000';
          }
        } 

        if(formObj.two_phases_sample == "No"){
          if(formObj.boiling_degassing_coefficient == null || formObj.boiling_degassing_coefficient == '')
          {
            if(formObj.boiling_temperature == null || formObj.boiling_temperature == ''){
            }
            else{
              formObj.boiling_degassing_coefficient = "1";
            }
          }
        }    
    }
    return formObj;
  }
 
  decline(): void {
    this.modalRef.hide();
  }

  public onReset(templateReset: TemplateRef<any>){
    this.modalReset = this.modalService.show(templateReset, {
      ariaDescribedby: 'my-modal-description',
      ariaLabelledBy: 'my-modal-title'
    });
  }

  public Reset(){
    this.formDetails.reset();
    this.modalReset.hide();
    this.CreateForm();
    this.getUser();
  }
  
  cancel(): void {
    this.modalReset.hide();
  }
ngOnDestroy(){
    this.dataService.changeShareInputData({});
  }

  

  ngAfterViewInit() {
    var navListItems = $('div.setup-panel div a'),
    allWells = $('.setup-content'),
    allNextBtn = $('.nextBtn'),
    allbackBtn = $('.backBtn');
    allWells.hide();

    navListItems.click(function (e) {
    e.preventDefault();
    var $target = $($(this).attr('href')),
        $item = $(this);

    if (!$item.hasClass('disabled')) {
        navListItems.removeClass('btn-success').addClass('btn-default');
        $item.addClass('btn-success');
        allWells.hide();
        $target.show();
        $target.find('input:eq(0)').focus();
    }
});

  $('div.setup-panel div a.btn-success').trigger('click');
  //   const allNextBtn = $('.nextBtn');
   allNextBtn.click(function () {	
    var curStep = $(this).closest(".setup-content"),
        curStepBtn = curStep.attr("id"),
        nextStepWizard = $('div.setup-panel div a[href="#' + curStepBtn + '"]').parent().next().children("a"),
        curInputs = curStep.find("input[type='text'],input[type='url']"),
        isValid = true;
      $(".form-group").removeClass("has-error");
      for (var i = 0; i < curInputs.length; i++) {
          if (!curInputs[i].validity.valid) {
              isValid = false;
              $(curInputs[i]).closest(".form-group").addClass("has-error");
          }
      }

      if (isValid) nextStepWizard.removeAttr('disabled').trigger('click');
  });
  
  // const allbackBtn =$('.backBtn');
    allbackBtn.click(function () {
      var curStep = $(this).closest(".setup-content"),
      curStepBtn = curStep.attr("id"),
      backStepWizard = $('div.setup-panel div a[href="#' + curStepBtn + '"]').parent().prev().children("a"),

      isValid = true;
      if (isValid) backStepWizard.removeAttr('disabled').trigger('click');
    })

  }
}


