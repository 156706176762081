import { createAction,props,Action  } from '@ngrx/store';
import { data } from 'jquery';

export const INPUT_DATA = '[WatchInputForm Component] INPUT_DATA';
export const SIMULATE_DATA = '[WatchInputForm Component] SIMULATE_DATA';

export class SimulateData implements Action{
    readonly type = SIMULATE_DATA;
    constructor(public payload : any){
    }
}

export type Actions =  SimulateData;
