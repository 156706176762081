import { Action, createReducer, on } from '@ngrx/store';

 
import * as WatchActions from './watch.action';

const initialState : any = {};

export function reducer(state = initialState, action: WatchActions.Actions){
  switch(action.type){
    case WatchActions.SIMULATE_DATA :
      const target = jsonCopy(action.payload);
      state = action.payload;
      return state;
    default :
    return state;  
  }
}

function jsonCopy(src: any) {
  return JSON.parse(JSON.stringify(src));
}

function steamCalculation(copypayload : any){
    const formObj : any= copypayload;
    const customObj : any = { 
      Customer : [],
      Sampling : [],
      Config : [],
      Water_phase : [],
      
      Steam : [{
        gas_CO2: "",
        gas_H2S: "",
        gas_NH3: "",
        gas_H2: "",
        gas_O2: "",
        gas_CH4:"",
        gas_N2: "",
        L_gas: "",
        gas_coll:"25.00000000",
        steam_CO2: "",
        steam_H2S:"",
        steam_NH3: "",
      }],
      Condensate_pH : [],
    };

    if(formObj.Sampling[0]['two_phases_sample'] == 'Yes'){
      const sum : any = ((Number.parseFloat(formObj.Steam[0]['gas_H2']) || 0) + 
                        (Number.parseFloat(formObj.Steam[0]['gas_O2']) || 0) + 
                        (Number.parseFloat(formObj.Steam[0]['gas_CH4']) || 0) + 
                        (Number.parseFloat(formObj.Steam[0]['gas_N2']) || 0))/100;
      //Steam & Gas
      for(const item in formObj.Steam[0]){
        if(formObj.Steam[0][item] == '' && item != 'steam_CO2' && item != 'steam_H2S' && item != 'steam_NH3' && item != 'L_gas' && item != 'gas_coll'){
          // formObj.Steam[0][item] = '0.00000000';
            customObj.Steam[0][item] = '0.00000000';
          }
         
          else{
            if(item == 'steam_CO2'){
              const steam_CO2 : any = Number.parseFloat(formObj.Steam[0]['gas_CO2']) * 24.422863485016;
              customObj.Steam[0]['steam_CO2'] = steam_CO2.toString();
              // formObj.Steam[0]['gas_CO2'] = '0.00000000';
              customObj.Steam[0]['gas_CO2'] = '0.00000000';
            }
            else if(item == 'steam_H2S'){
              const steam_H2S : any = Number.parseFloat(formObj.Steam[0]['gas_H2S']) * 18.9123196448391;
              customObj.Steam[0]['steam_H2S'] = steam_H2S.toString();
              // formObj.Steam[0]['gas_H2S'] = '0.00000000';
              customObj.Steam[0]['gas_H2S'] = '0.00000000';
            }
            else if(item == 'steam_NH3'){
              const steam_NH3 : any = Number.parseFloat(formObj.Steam[0]['gas_NH3']) * 9.45061043285239;
              customObj.Steam[0]['steam_NH3'] = steam_NH3.toString();
              // formObj.Steam[0]['gas_NH3'] = '0.00000000';
              customObj.Steam[0]['gas_NH3'] = '0.00000000';
            }
      
            if(item == 'L_gas'){
              const L_gas_val : any = Number.parseFloat(sum) * 1.35768056032398;
              // formObj.Steam[0][item] = L_gas_val.toString();
              customObj.Steam[0][item] = L_gas_val.toString();
            }

            if((item == 'gas_H2') || (item == 'gas_O2') || (item == 'gas_CH4') || (item == 'gas_N2')){
              const val : any =  formObj.Steam[0][item];
                if(Number.parseFloat(val) == 0 && sum == 0){
                  // formObj.Steam[0][item] = '0';
                  customObj.Steam[0][item] = '0';
                }
                else{
                  const cal : any = Number.parseFloat(val)/Number.parseFloat(sum);
                  // formObj.Steam[0][item] = cal;
                  customObj.Steam[0][item] = cal;
                }
              }
            
              const num  = Number.parseFloat(formObj.Steam[0][item]).toFixed(8);
                // formObj.Steam[0][item] = num;
                customObj.Steam[0][item] = num;
              }
        }
    }
    else{
      for(const item in formObj.Steam[0]){
        if(item != 'gas_coll' && formObj.Steam[0][item] == '')
            // formObj.Steam[0][item] = '0.00000000';
            customObj.Steam[0][item] = '0.00000000';
        }
      }

      customObj.Customer.push(formObj.Customer[0]);
      customObj.Sampling.push(formObj.Sampling[0]);
      customObj.Config.push(formObj.Config[0]);
      customObj.Water_phase.push(formObj.Water_phase[0]);
      customObj.Condensate_pH.push(formObj.Condensate_pH[0]);
      
    return customObj;
  }
