import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {  AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {
  public model : any = {};

  constructor(
    private router: Router,
    private authenticationService : AuthenticationService
  ) { }

  ngOnInit() {
    this.getUser();
  }

  public getUser(){

    const currentUser = this.authenticationService.currentUserValue;
        if (currentUser.email) {
            this.model.email = currentUser.email;
        }
  }

  public toggleSidebar(e: { preventDefault: () => void; }){

    e.preventDefault();
    var element = document.getElementById("wrapper");
    element.classList.toggle("toggled");
  }

  public onlogOut(){
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('currentToken');
    this.authenticationService.currentUserSubject.next(null);
    this.authenticationService.currentTokenubject.next(null);
    this.router.navigate(['/login']);
  }

}
