import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { LayoutComponent } from './layout/layout.component';
import { WatchInputFormComponent } from './watch-input-form/watch-input-form.component';
import { CurrentStimulationComponent } from './current-stimulation/current-stimulation.component';
import { AuthGuard } from './_helpers';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
  { path: '', component: LoginComponent},
  { path: 'login', component: LoginComponent },
  { path: 'home', component: LayoutComponent,
    children: [
      { path: '', component: WatchInputFormComponent,canActivate: [AuthGuard] },
      { path: 'watchinput-form', component: WatchInputFormComponent ,canActivate: [AuthGuard] },
      { path: 'current-stimulation', component: CurrentStimulationComponent,canActivate: [AuthGuard]},
    ]
  },
  { path: 'register', component: RegisterComponent },
  {path: '404', component: NotFoundComponent},
  {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
