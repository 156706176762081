import { Component, OnInit ,ViewChild ,Input,ElementRef, TemplateRef } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse }   from '@angular/common/http';
import { Injectable } from "@angular/core"
import { Observable, of } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef,ModalDirective  } from 'ngx-bootstrap/modal';  
import { DataService } from '../_services/data.service';
import { NgxSpinnerService } from "ngx-spinner"; 

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
    bsModalRef: BsModalRef;  

   constructor(private modalService: BsModalService,private toasterService: ToastrService, private dataService : DataService,private SpinnerService: NgxSpinnerService) { }  

intercept(
        req: HttpRequest<any>,
        next: HttpHandler
      ): Observable<HttpEvent<any>> {
        this.SpinnerService.show();  
        return next.handle(req).pipe(
            tap(evt => {
                if (evt instanceof HttpResponse) {
			 this.SpinnerService.hide();
                }
                return evt;
            }),
            catchError((err: any) => {
                this.SpinnerService.hide();
                const errorMessage = 'Unknown error!';
                
                if(err instanceof HttpErrorResponse) {
                    if(err.error && typeof(err.error) == 'object'){
                        if(typeof((err.error).error) == 'string'){
                            this.toasterService.error(`${(err.error).error}\n`,"",  { positionClass: 'toast-top-center', timeOut : 30000000 });
                        }
                    }
                    try {
                        if(err.status == 0 || err.status == 500){
                            this.toasterService.error(`The server encountered an error; the issue has been logged.  Please try again later or contact SUPPORT. `,"",  { positionClass: 'toast-top-center', timeOut : 30000000 });
                        }
                        else if(err.status == 400){
                            this.toasterService.error(`Unexpected input value encountered.Please refer to the USER GUIDE `,"",  { positionClass: 'toast-top-center', timeOut : 30000000 });
                        }
                        else if(err.status == 404){
                            this.toasterService.error(`Unable to find dataset.If this is in error, please contact SUPPORT`,"",  { positionClass: 'toast-top-center', timeOut : 30000000 });
                        }
                        else{
                            this.toasterService.error(err.message, "", { positionClass: 'toast-top-center', timeOut : 30000000 });
                        }
                    } catch(e) {
                        this.toasterService.error('An error occurred', '', { positionClass: 'toast-top-center', timeOut : 30000000 });
                    }
                    const initialState = {
                        parameter: 2019,
                    };
                }
                return of(err);
            }));
      }
}

