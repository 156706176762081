import { Component, ChangeDetectionStrategy, OnInit, TemplateRef ,AfterViewInit, ViewChild, ViewContainerRef,ElementRef} from '@angular/core';
import { FormBuilder, FormGroup,FormControl, Validators,FormArray, FormControlName,AbstractControl,ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import {  AuthenticationService } from '../_services/authentication.service';
import { HttpRequestService } from '../_services/http-req-service';
import { DataService } from '../_services/data.service';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label,BaseChartDirective } from 'ng2-charts';
import { Chart } from 'chart.js';
import { TimestampService} from '../_services/timestamp.service';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import * as WatchActions from '../_services/watch.action';
import { Observable } from 'rxjs';
import * as jspdf from 'jspdf';  
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-current-stimulation.component',
  templateUrl: './current-stimulation.component.html',
  styleUrls: ['./current-stimulation.component.css']
})
export class CurrentStimulationComponent {
  public currentData : any = {} || null;
  public name : any;
  public isWatchOut : boolean = false;
  public watchOutData : any = {} || null;
  public degassingCoeff : any = [];
  public sampleNoList : any =[];
  public colIndex : any = 1;
  public degassCoeffMeasuerment : any = [];
  public meauermentData : any = [];
  public measurdObjData : any = [];
  public path : any;
  public ischeck : boolean = false;
  public storeData : Observable<any>;
  public dataSubscription : any ;
  public modalRef: BsModalRef;
  public modalSaveRef : BsModalRef;
  public modalReset : BsModalRef;
  public watchOutArrayData : any =[];
  public watchOutDataTwo : any = {} || null;
  public watchOutDataThree : any = {} || null;
  public demoAppData: any = [];
  public tableLable: any = {};
  public isChecked : boolean = false;

  constructor(private store: Store<any>, private router : Router,private httpRequestService : HttpRequestService, private dataService : DataService,private timestampService: TimestampService,private toasterService: ToastrService,private elementRef:ElementRef,private modalService: BsModalService,
    private authenticationService : AuthenticationService) {
  }

      lineChartData: ChartDataSets[] = [
        { data: [], label: "" ,
	borderColor: ["#4F80F8"],
        backgroundColor:"#e2e2e2"},
        // { data: [], label:""},
        // { data: [], label: "" },
      ];

      lineChartLabels: Label[] = [];

      lineChartOptions = {
        responsive: true,
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: ''
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Flash Temperature(°C)'
            }
          }],
        },
      };

      lineChartColors: Color[] = [
        {
          // borderColor: 'black',
           // backgroundColor: #d8d8d8,
        },
      ];

      lineChartLegend = true;
      lineChartPlugins = [];
      lineChartType = 'line';

  
  public ngOnInit(): void {
     this.loadDetails();
  }
  
  public loadDetails(){
   this.dataSubscription =  this.store.select('watchdata').subscribe(res =>   {
     let data : any = {};
     Object.assign(data, res);
     
     if(Object.keys(data).length != 0){
      this.currentData = data;
      this.currentData.utcTime = this.timeCoverttoUTC(this.currentData.timecreated) + "(UTC)";
      if(this.isChecked == false){
        this.isChecked = true;
        this.onClickSpecices('calcite');
      }
      this.ischeck = true;
     }
    });
  }

  public timeCoverttoUTC(timecreated: any){
    const time : any= this.timestampService.getConvertedUnixTime(timecreated);
    return  time.utc.fullDateTime;
  }

  public loadSpeciesData(species_val: any){
    const customer_id : any= this.currentData.customer_id;
    const name : any= this.currentData.name
    const parameter  : any =  species_val;
    const sample_name : any = this.currentData.sample_name;
    const location : any = this.currentData.location;
    let timecreated : any = this.currentData.timecreated;
    if(timecreated == 'null'){
      timecreated = null;
    }

     this.lineChartData[0].data = [];
      this.lineChartData[0].label = '';
      if(this.lineChartData.length>1){
        this.lineChartData[1].data = [];
        this.lineChartData[1].label = '';
        if(this.lineChartData.length>2){
          this.lineChartData[2].data = [];
          this.lineChartData[2].label = '';
        }
      }
      this.tableLable.deg_Coeff = [];
      this.lineChartLabels = [];
      this.tableLable = {};
      this.isWatchOut = false;
      const tokenDetails = this.authenticationService.getTokenDetails();
      this.httpRequestService.postRefreshtoken(tokenDetails).subscribe(data=>{
      const token = data.token;
       this.httpRequestService.getSpeciesData(customer_id,timecreated, parameter,token).subscribe(data =>{
         this.demoAppData = data;
        this.displayTableGraph(parameter);
        });
      });
      }


  public displayTableGraph(parameter: any){
 if( this.demoAppData.length>=0){
    this.tableLable.count = this.demoAppData.length;
    if(this.tableLable.count>0){
        this.isWatchOut = true;
    }
    else{
        this.isWatchOut = false;
        this.toasterService.info("No Data available","",{ positionClass: 'toast-top-center' });
    }
this.tableLable.deg_Coeff = [];
    for(let j=0; j<=this.demoAppData.length-1; j++){
	if(j<3){
        const data : any = this.demoAppData[j];
        this.tableLable.deg_Coeff.push("Degassing Coeff ="+ data.degassing_Coeff);
        for(let i =1; i<=10; i++){
            if(parameter == "pH"){
                if(data["measured_parameter_pH"] == "pH"){
                    this.lineChartData[j].label = data["measured_parameter_pH"] + " " + 'After Flashing ' + data.degassing_Coeff + " degassing Coeff";
                    this.lineChartOptions.scales.yAxes[0].scaleLabel.labelString = "Brine " + data["measured_parameter_pH"] + " After Flashing";
                    this.tableLable.measured_parameter = data["measured_parameter_pH"];
                    const val: any = this.ConvertString(data["pH_temp"+i]).toFixed(3);
                    const temperature_val : any = Math.trunc(data["temp"+i]);
                    if(val && temperature_val){
                          this.lineChartData[j].data.push(val);
                          if(j == 0){
                            this.lineChartLabels.push(temperature_val);
                          }
                      }
                }
            }

            if(parameter == "calcite"){
                if(data["measured_parameter_Calcite"] == "calcite"){
                    this.lineChartData[j].label = data.degassing_Coeff + " degassing Coeff";
                    this.lineChartOptions.scales.yAxes[0].scaleLabel.labelString = this.titleCaseWord(data["measured_parameter_Calcite"]) + " Saturation Index";
                    
                    this.tableLable.measured_parameter = this.titleCaseWord(data["measured_parameter_Calcite"]);

                    const val: any =  (this.ConvertString(data["Calcite_logQ_temp"+i]) - this.ConvertString(data["Calcite_logK_temp"+i])).toFixed(3);
                    const temperature_val : any = Math.trunc(data["temp"+i]);
                    if(val && temperature_val){
                        this.lineChartData[j].data.push(val);
                        if(j == 0){
                          this.lineChartLabels.push(temperature_val);
                        }
                    }
                }
            }

            if(parameter == "anhydrite"){
                if(data["measured_parameter_Anhydrite"] == "anhydrite"){
                    this.lineChartData[j].label = data["measured_parameter_Anhydrite"] + " " + 'After Flashing ' + data.degassing_Coeff + " degassing Coeff";
                    this.lineChartOptions.scales.yAxes[0].scaleLabel.labelString = this.titleCaseWord(data["measured_parameter_Anhydrite"]) + " After Flashing";
                    
                    this.tableLable.measured_parameter = this.titleCaseWord(data["measured_parameter_Anhydrite"]);

                    const val: any =  (this.ConvertString(data["Anhydrite_logQ_temp"+i]) - this.ConvertString(data["Anhydrite_logK_temp"+i])).toFixed(3);
                    const temperature_val : any = Math.trunc(data["temp"+i]);
                    if(val && temperature_val){
                        this.lineChartData[j].data.push(val);
                        if(j == 0){
                          this.lineChartLabels.push(temperature_val);
                        }
                    }
                }
            }

            if(parameter == "pyrite"){
                if(data["measured_parameter_Pyrite"] == "pyrite"){
                    this.lineChartData[j].label = data["measured_parameter_Pyrite"] + " " + 'After Flashing ' + data.degassing_Coeff + " degassing Coeff";
                    this.lineChartOptions.scales.yAxes[0].scaleLabel.labelString = this.titleCaseWord(data["measured_parameter_Pyrite"]) + " After Flashing";
                    
                    this.tableLable.measured_parameter = this.titleCaseWord(data["measured_parameter_Pyrite"]);

                    const val: any =  (this.ConvertString(data["Pyrite_logQ_temp"+i]) - this.ConvertString(data["Pyrite_logK_temp"+i])).toFixed(3);
                    const temperature_val : any = Math.trunc(data["temp"+i]);
                    if(val && temperature_val){
                        this.lineChartData[j].data.push(val);
                        if(j == 0){
                          this.lineChartLabels.push(temperature_val);
                        }
                    }
                }
            }

            if(parameter == "silamorph"){
                if(data["measured_parameter_Silamorph"] == "silamorph"){
                    this.lineChartData[j].label = data["measured_parameter_Silamorph"] + " " + 'After Flashing ' + data.degassing_Coeff + " degassing Coeff";
                    this.lineChartOptions.scales.yAxes[0].scaleLabel.labelString = this.titleCaseWord(data["measured_parameter_Silamorph"]) + " After Flashing";
                    
                    this.tableLable.measured_parameter = this.titleCaseWord(data["measured_parameter_Silamorph"]);

                    const val: any =  (this.ConvertString(data["Silamorph_logQ_temp"+i]) - this.ConvertString(data["Silamorph_logK_temp"+i])).toFixed(3);
                    const temperature_val : any = Math.trunc(data["temp"+i]);
                    if(val && temperature_val){
                        this.lineChartData[j].data.push(val);
                        if(j == 0){
                          this.lineChartLabels.push(temperature_val);
                        }
                    }
                }
            }
        }
      }
    }
  }
}

  public titleCaseWord(word: string) {
    if (!word) return word;
    if(word != 'pH'){
      return word[0].toUpperCase() + word.substr(1).toLowerCase();
    }
    else{
      return 'pH'
    }
  }

  public  ConvertString(value: string){
    return parseFloat(value)
    }

    public onClickSpecices(species_val: any){
      this.isWatchOut = false;
      this.loadSpeciesData(species_val);
    }

    public onClickBack(){
      const id : any= this.currentData.customer_id
      const time : any = this.currentData.timecreated;

      const tokenDetails = this.authenticationService.getTokenDetails();
        this.httpRequestService.postRefreshtoken(tokenDetails).subscribe(data=>{
        const token = data.token;
        this.httpRequestService.getInputFormData(id, time,token).subscribe(data =>{
            let tempdata : any = [];
            tempdata  = data;
            if(tempdata.length>0){
            const shareData : any = {
              key : "current_simulation",
              data : data[0],
              timecreated : time
            }
            this.dataService.changeShareInputData(shareData);
            this.router.navigate(['/home']);
            }
            else
            {
              this.toasterService.info("No Data available","",{ positionClass: 'toast-top-center' });
            }
          });
      });
    }  

    ngOnDestroy(){
    }

    public openSaveModel(template: TemplateRef<any>){
        this.modalRef = this.modalService.show(template, {
        ariaDescribedby: 'my-modal-description',
        ariaLabelledBy: 'my-modal-title'
      });
    }

    public saveData(){
      this.modalRef.hide();
      this.router.navigate(['/home']);
     
    }
    decline(): void {
    this.modalRef.hide();
  }

public onClickPrint(){
    window.print();

   //  var data = document.getElementById('contentToConvert');  //Id of the table
   //  html2canvas(data).then(canvas => {  
   //   // Few necessary setting options  

   //   let imgWidth = 208;   
   //   let pageHeight = 295;    
   //   let imgHeight = canvas.height * imgWidth / canvas.width;  
   //   let heightLeft = imgHeight;  
   //   const contentDataURL = canvas.toDataURL('image/png')  
   //   var pdf = new jspdf.jsPDF('p','mm','a4');
   //   let position = 0;  
    //  pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
   //   pdf.save('CurrentSimulation.pdf'); // Generated PDF   
   //  });  
  }
  }