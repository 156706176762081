import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { HttpClient, HttpHeaders, HttpErrorResponse} from "@angular/common/http";
import { Observable, BehaviorSubject, throwError,Subject } from 'rxjs';
import { catchError } from 'rxjs/operators'
import { AppHttpInterceptor } from '../_services/http.interceptor';
import { DataService } from '../_services/data.service';
import { getData , Admin} from '../_models/admin';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class HttpRequestService {
  public dataToDisplay: any[];
  public data: any[];
  public errorobj : any = {} || null;
  httpOptions : any  = {};

  constructor(private modalService: BsModalService, private http: HttpClient,private dataService : DataService, private authenticationService : AuthenticationService) {
    const token = this.authenticationService.currentTokenValue;
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      })
    }
  }
	
  // endpoint = "http://127.0.0.1:8000/";
  // endpoint = "http://172.0.48.68:8000/";
  // endpoint = "http://172.0.36.6:8000/";
  //endpoint = "https://20.44.78.161/";
     endpoint = "https://geosol-app-api.solenis.com/";

  public postRefreshtoken(data: any):Observable<any>{
    const url = this.endpoint + "api/refreshtoken/" ;
    return this.http.post(url, data, this.httpOptions,);
  }

  public postFormData(data: any,token:any): Observable<any> {
    const url = this.endpoint + "api/watch/input/";
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': `Bearer ${token}`
      })
    }

    return this.http.post(url, data,httpOptions );
  }

  public getInputFormData(id:any, time:any,token:any){
    const url = this.endpoint + "api/watch/input/customer/"+ id+"/key/"+time;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': `Bearer ${token}`
      })
    }

    return this.http.get(url,httpOptions )
  }

  public getVerifyEmail(email: any):Observable<any>{
    const url = this.endpoint + "api/verify/"+email+"/" ;
    return this.http.get(url, this.httpOptions );
  }

  public postSendEmail(data: any):Observable<any>{
    const url = this.endpoint + "api/sendemail/";
    return this.http.post(url, data, this.httpOptions )
  }

  public postOTPDetails(data: any):Observable<any>{
    const url = this.endpoint + "api/register/";
    return this.http.post(url, data, this.httpOptions )
  }

  public getSpeciesData(customer_id: any, timecreated:any, parameter: any,token:any): Observable<any> {
    const url = this.endpoint + "api/watch/species/customer/"+ customer_id+"/key/"+timecreated +"/specie/"+ parameter+"/";
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': `Bearer ${token}`
      })
    }
    return this.http.get(url,httpOptions )
  }

}


  
    
