import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../_models/user';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public currentUserSubject: BehaviorSubject<any>;
    public currentTokenubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;
    public currentToken: Observable<any>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(sessionStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();

        this.currentTokenubject = new BehaviorSubject<any>(JSON.parse(sessionStorage.getItem('currentToken')));
        this.currentToken = this.currentTokenubject.asObservable();
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    public get currentTokenValue(): any {
        return this.currentTokenubject.value;
    }

    public getTokenDetails(){
        const currentUser = this.currentUserValue;
        const currentToken = this.currentTokenValue;
        const data = {
          email: currentUser.email,
          tokencode :currentToken
        }
        return data;
    } 
     
    public isAuthenticated(): boolean {
        const currentUserVal = this.currentUserValue;
        if (currentUserVal) {
            // authorised so return true
            return true;
        }
      }
    
}