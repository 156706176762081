import { Component, OnInit,ElementRef, ViewChild  } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { HttpRequestService } from '../_services/http-req-service';
import {  AuthenticationService } from '../_services/authentication.service';
import { ChangeDetectorRef, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { combineLatest, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery' ;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
  })

export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    modalRef: BsModalRef;
    model: any = {};
    islogin : boolean = true;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private modalService: BsModalService,
        public httpRequestService : HttpRequestService,
        public toasterService: ToastrService,
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) { 
            this.router.navigate(['/']);
        }
        this.islogin = true;
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required,Validators.pattern("^[_A-Za-z0-9-\+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$")]],
            
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        // this.addRecaptchaScript();
        this.islogin = true;
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

        config = {
      allowNumbersOnly: false,
      length: 6,
      isPasswordInput: false,
      disableAutoFocus: false,
      placeholder: '',
      inputStyles: {
        'width': '40px',
        'height': '40px'
      }
    };

    onOtpChange(otp) {
        this.model.model_otp = otp;
      }

    openModal() {
        this.submitted = true;
      
        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }
        else{             
            const email : any = this.loginForm.controls['email'].value;
            this.httpRequestService.getVerifyEmail(email).subscribe(data=>{
                if(data.length>0){
                    const obj :any = this.loginForm.value;
                    this.httpRequestService.postSendEmail(obj).subscribe(data=>{
                        if(Object.keys(data).length>0){
                            this.model.model_otp = "";
                            this.islogin = false;
                        }
                        else{
                            
                        }
                    });
                }
                else{
                    this.toasterService.error("Email Address is not registered","",{ positionClass: 'toast-top-center' });
                }
            });
        }
      }

    onResendOTP(){
        const obj :any = this.loginForm.value;
        this.httpRequestService.postSendEmail(obj).subscribe(data=>{
            if(Object.keys(data).length>0){
                this.toasterService.info("OTP sent to your registered Email Address","",{ positionClass: 'toast-top-center' });
            }
            else{
                this.toasterService.error("Resend OTP","",{ positionClass: 'toast-top-center' });
            }
        });
    }

    submitOTP(){
        const data = {
            action: "LOGIN",
            email: this.loginForm.controls['email'].value,
            otp: this.model.model_otp
            }
            this.httpRequestService.postOTPDetails(data).subscribe(data =>{
                if(data.status == "Authenticated"){
                    sessionStorage.setItem('currentUser', JSON.stringify(data));
                    this.authenticationService.currentUserSubject.next(data); 
                    sessionStorage.setItem('currentToken', JSON.stringify(this.model.model_otp));
                    this.authenticationService.currentTokenubject.next(this.model.model_otp); 
                    this.model.model_otp = "";
                    this.router.navigate(['/home']);
                }
                else{
                    alert("Not a Valid User")
                }
            });
        }

        onRegister(){
            this.router.navigate(['/register']);
        }
    }