import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule,FormsModule} from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { LayoutComponent } from './layout/layout.component';
import { ModalModule } from 'ngx-bootstrap/modal';  
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ChartsModule,ThemeService } from 'ng2-charts';
import { ToastrModule } from 'ngx-toastr';
import { HttpRequestService } from './_services/http-req-service';
import { WatchInputFormComponent } from './watch-input-form/watch-input-form.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsModalRef,BsModalService} from 'ngx-bootstrap/modal';
import { NgbModule ,NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { CurrentStimulationComponent } from './current-stimulation/current-stimulation.component';
import { DataService } from './_services/data.service';
import { TimestampService} from './_services/timestamp.service';
import { CapitalizeFirstPipe } from './_pipes/titlecaseword-pipe';
import { StoreModule } from '@ngrx/store';
import { reducer } from './_services/watch.reducer';
import { AuthGuard } from './_helpers/auth.guard';
import { Role } from './_models/role';
import { CommonModule } from '@angular/common';
import { AuthenticationService } from './_services/authentication.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotFoundComponent } from './not-found/not-found.component';
import { NumericDirective } from './_directives/numeric-directive';
import { AppHttpInterceptor } from './_services/http.interceptor';
import { NgxSpinnerModule,NgxSpinnerService } from "ngx-spinner";
import { NgOtpInputModule } from  'ng-otp-input';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    LayoutComponent,
    WatchInputFormComponent,
    CurrentStimulationComponent,
    CapitalizeFirstPipe,
    NotFoundComponent,
    NumericDirective,
    
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgOtpInputModule,
    HttpClientModule,
    ModalModule.forRoot(), 
    ToastrModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    NgxSpinnerModule,
    StoreModule.forRoot({ watchdata : reducer }),
  ],
  providers: [
    ThemeService,
    HttpRequestService,
    DataService,
    TimestampService,
    AuthenticationService,
    NgxSpinnerService,
    {
      provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
