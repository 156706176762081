import { Injectable } from "@angular/core";
import { BehaviorSubject ,Subject,Observable} from 'rxjs';

@Injectable()

export class DataService {
    public data: any = {};
    // public subject = new Subject<any>();
    public SharingInputData = new Subject();
    public SharingCustomerData = new Subject();

    private ShareInputData = new BehaviorSubject(this.data);
    ShareInputDataSource = this.ShareInputData.asObservable();

    private ShareCustomerData = new BehaviorSubject(this.data);
    currentCustomerDataSource = this.ShareCustomerData.asObservable();

    private ShareCurrentUserDetails = new BehaviorSubject(this.data);
    CurrentUserDetails = this.ShareCurrentUserDetails.asObservable();


    public changeShareInputData(data: any) {
      this.ShareInputData.next(data)
    }

    public changeCustomerData(data: any) {
      this.ShareCustomerData.next(data)
    }

    public changeCurrentUserDetails(data: any) {
      this.ShareCurrentUserDetails.next(data)
    }

    constructor(){}

    ngOninit(){}
}