import { Injectable } from '@angular/core';  
import { ValidatorFn, AbstractControl } from '@angular/forms';  
import { FormGroup } from '@angular/forms';  

@Injectable({  
  providedIn: 'root'  
})  

export class CustomvalidationService {    
    emailValidation() {  
    return (control: AbstractControl) => { 
      let emailControl :any= control.value;  
      if(emailControl != ""){
      if(emailControl.includes("@solenis.com") || (emailControl == "vreyeso@gdragon.com.mx") ||  (emailControl == "focampoa@gdragon.com.mx") || ( emailControl == "sahat.berutu@sarulla-geothermal.com") || (emailControl == "apjohnson@ormat.com") || (emailControl== "claire.newton@mercury.co.nz")  ){
          return null
      }
      else{
       return { emailInvalide: true }; 
      }
      }
    }  
  } 
}
